import {
  Array,
  Boolean,
  Literal,
  Number,
  Optional,
  Record,
  Static,
  String,
  Union,
} from "runtypes";

import {
  ChartAxisGrid,
  ChartAxisTicks,
  ChartBarLayout,
  ChartColorHex,
  ChartColorMappings,
  ChartColorScheme,
  ChartDataLabels,
  ChartDataTypeLiteral,
  ChartGeneralSettings,
  ChartHistogramFormat,
  ChartInterpolate,
  ChartLineStroke,
  ChartNumberAxisScale,
  ChartOpacitiesBySeriesValues,
  ChartSeriesTypeLiteral,
  ChartShape,
  ChartSortOrCustomSort,
} from "../chart/types.js";
import {
  DateColumnDisplayFormat,
  DatetimeColumnDisplayFormat,
  NumberColumnDisplayFormat,
} from "../display-table/columnDisplayFormatTypes.js";
import {
  CalciteTypeLiteral,
  HqlAggregationFunctionLiteral,
  HqlTruncUnitLiteral,
} from "../hql/types.js";

export const ExploreFieldId = String.withBrand("ExploreFieldId");
export type ExploreFieldId = Static<typeof ExploreFieldId>;

export const ExploreAxisId = String.withBrand("ExploreAxisId");
export type ExploreAxisId = Static<typeof ExploreAxisId>;

export const ExploreSeriesId = String.withBrand("ExploreSeriesId");
export type ExploreSeriesId = Static<typeof ExploreSeriesId>;

export const ChartExploreChannelTopLevel = Union(
  Literal("x-axis"),
  Literal("h-facet"),
  Literal("v-facet"),
);
export type ChartExploreChannelTopLevel = Static<
  typeof ChartExploreChannelTopLevel
>;

export const ChartExploreChannelOnePerSeries = Union(
  Literal("y-axis"),
  Literal("color"),
  Literal("opacity"),
);
export type ChartExploreChannelOnePerSeries = Static<
  typeof ChartExploreChannelOnePerSeries
>;

export const ChartExploreChannelManyPerSeries = Union(Literal("tooltip"));
export type ChartExploreChannelManyPerSeries = Static<
  typeof ChartExploreChannelManyPerSeries
>;

export const PivotExploreChannel = Union(
  Literal("row"),
  Literal("column"),
  Literal("value"),
);
export type PivotExploreChannel = Static<typeof PivotExploreChannel>;

export const ExploreChannel = Union(
  ...ChartExploreChannelTopLevel.alternatives,
  ...ChartExploreChannelOnePerSeries.alternatives,
  ...ChartExploreChannelManyPerSeries.alternatives,
  ...PivotExploreChannel.alternatives,
);
export type ExploreChannel = Static<typeof ExploreChannel>;

export const ExploreColorSource = Union(Literal("color"), Literal("x-axis"));
export type ExploreColorSource = Static<typeof ExploreColorSource>;

export const ExploreOpacitySource = Union(
  Literal("opacity"),
  Literal("color"),
  Literal("x-axis"),
);
export type ExploreOpacitySource = Static<typeof ExploreOpacitySource>;

export const ExploreAxis = Record({
  scale: Optional(ChartNumberAxisScale),
  position: Optional(
    Union(Literal("top"), Literal("bottom"), Literal("left"), Literal("right")),
  ),
  labelAngle: Optional(Number),
  grid: Optional(ChartAxisGrid),
  ticks: Optional(ChartAxisTicks),
  min: Optional(Number),
  max: Optional(Number),
  zero: Optional(Boolean),
});
export type ExploreAxis = Static<typeof ExploreAxis>;

export const ExploreOpacity = Record({
  source: ExploreOpacitySource,
  staticValue: Optional(Number),
  staticMode: Optional(Boolean),
});
export type ExploreOpacity = Static<typeof ExploreOpacity>;

export const ExploreBin = Record({
  size: Optional(Number),
  count: Optional(Number),
});

export const ExploreInterpolate = ChartInterpolate;
export type ExploreInterpolate = Static<typeof ExploreInterpolate>;

export const ExploreTextStyles = ChartDataLabels;
export type ExploreTextStyles = Static<typeof ExploreTextStyles>;

export const ExploreBarStyles = Record({
  orientation: Optional(Union(Literal("vertical"), Literal("horizontal"))),
  layout: ChartBarLayout,
});
export type ExploreBarStyles = Static<typeof ExploreBarStyles>;

export const ExploreLineStyles = Record({
  point: Boolean,
  interpolate: Optional(ChartInterpolate),
  stroke: Optional(ChartLineStroke),
  width: Optional(Number),
});
export type ExploreLineStyles = Static<typeof ExploreLineStyles>;

export const ExploreScatterStyles = Record({
  filled: Optional(Boolean),
  shape: Optional(ChartShape),
  size: Optional(Number),
});
export type ExploreScatterStyles = Static<typeof ExploreScatterStyles>;

export const ExploreAreaStyles = Record({
  line: Boolean,
  point: Boolean,
  interpolate: Optional(ExploreInterpolate),
  layout: Optional(Union(Literal("stacked"), Literal("stacked100"))),
});
export type ExploreAreaStyles = Static<typeof ExploreAreaStyles>;

export const ExplorePieStyles = Record({
  radius: Optional(Number),
  showAsPct: Optional(Boolean),
});
export type ExplorePieStyles = Static<typeof ExplorePieStyles>;

export const ExploreHistogramStyles = Record({
  format: Optional(ChartHistogramFormat),
  useColumn: Optional(Boolean),
});
export type ExploreHistogramStyles = Static<typeof ExploreHistogramStyles>;

export const ExploreColor = Record({
  source: ExploreColorSource,
  scheme: Optional(ChartColorScheme),
  reverse: Optional(Boolean),
  staticValue: Optional(ChartColorHex),
});
export type ExploreColor = Static<typeof ExploreColor>;

export const ExploreFacet = Record({
  columns: Optional(Number),
  sharedY: Optional(Boolean),
});
export type ExploreFacet = Static<typeof ExploreFacet>;

export const ExploreField = Record({
  id: ExploreFieldId,
  value: String,
  seriesId: ExploreSeriesId,
  channel: ExploreChannel,
  dataType: CalciteTypeLiteral,
  title: Optional(String), //TODO(EXPLORE) should this appear in pivot, in the field pills?
  displayFormat: Optional(
    Union(
      NumberColumnDisplayFormat,
      DateColumnDisplayFormat,
      DatetimeColumnDisplayFormat,
    ),
  ),
  aggregation: Optional(HqlAggregationFunctionLiteral),
  scaleType: Optional(ChartDataTypeLiteral),
  truncUnit: Optional(HqlTruncUnitLiteral),
  axis: Optional(ExploreAxis),
  sort: Optional(ChartSortOrCustomSort),
  bin: Optional(ExploreBin),
  colorsBySeriesValues: Optional(ChartColorMappings),
  opacitiesBySeriesValues: Optional(ChartOpacitiesBySeriesValues),
});
export type ExploreField = Static<typeof ExploreField>;
// Explore field configuration that can be simply adjusted
// without other major structural changes to the related explore spec
export type ExploreFieldSimpleOptions = Omit<
  ExploreField,
  "id" | "value" | "seriesId" | "channel" | "dataType"
>;

export const ExploreViewType = Union(
  Literal("source-table"),
  Literal("visualization"),
);
export type ExploreViewType = Static<typeof ExploreViewType>;

export const ExploreVisualizationType = Union(
  Literal("pivot-table"),
  Literal("chart"),
);
export const ExploreTooltipMode = Union(
  Literal("auto"),
  Literal("manual"),
  Literal("none"),
);
export type ExploreVisualizationType = Static<typeof ExploreVisualizationType>;

export const ExploreChartSeries = Record({
  id: ExploreSeriesId,
  type: ChartSeriesTypeLiteral,
  color: Optional(ExploreColor),
  opacity: Optional(ExploreOpacity),
  name: Optional(String),
  dataLabels: Optional(ChartDataLabels),
  totalDataLabels: Optional(ChartDataLabels),
  tooltipMode: ExploreTooltipMode,

  // per-series styles
  barStyles: Optional(ExploreBarStyles),
  lineStyles: Optional(ExploreLineStyles),
  scatterStyles: Optional(ExploreScatterStyles),
  areaStyles: Optional(ExploreAreaStyles),
  pieStyles: Optional(ExplorePieStyles),
  textStyles: Optional(ExploreTextStyles),
  histogramStyles: Optional(ExploreHistogramStyles),
});
export type ExploreChartSeries = Static<typeof ExploreChartSeries>;

export const ExploreChartSettings = ChartGeneralSettings.omit(
  "selectionEnabled",
  "tooltip",
);
export type ExploreChartSettings = Static<typeof ExploreChartSettings>;

export const ExploreChartConfig = Record({
  series: Array(ExploreChartSeries),
  seriesGroups: Array(Array(ExploreSeriesId)),
  facet: Optional(ExploreFacet),
  settings: ExploreChartSettings,
});
export type ExploreChartConfig = Static<typeof ExploreChartConfig>;

export const ExploreSpec = Record({
  fields: Array(ExploreField),
  chartConfig: ExploreChartConfig,
  visualizationType: ExploreVisualizationType,
});
export type ExploreSpec = Static<typeof ExploreSpec>;
