import { ChartDataType } from "../chart/types.js";
import { DisplayTableColumnOutputType } from "../display-table/outputTypes.js";
import { assertNever, guardNever } from "../errors.js";
import {
  CalciteType,
  HqlAggregationFunction,
  HqlTruncUnit,
} from "../hql/types.js";
import { uuid } from "../uuid.js";

import {
  ExploreChannel,
  ExploreChartSeries,
  ExploreField,
  ExploreFieldId,
  ExploreSeriesId,
  ExploreSpec,
} from "./types.js";

export function defaultExploreSpec(noSeries?: boolean): ExploreSpec {
  const initialSeries = defaultExploreChartSeries();
  return {
    fields: [],
    visualizationType: "pivot-table",
    chartConfig: {
      series: noSeries ? [] : [initialSeries],
      seriesGroups: noSeries ? [] : [[initialSeries.id]],
      settings: {
        legend: {
          position: "right",
        },
      },
    },
  };
}

export function defaultExploreChartSeries(
  type: "bar" | "line" = "bar",
): ExploreChartSeries {
  if (type === "bar") {
    return {
      id: ExploreSeriesId.check(uuid()),
      type,
      tooltipMode: "auto",
      barStyles: {
        orientation: "vertical",
        layout: "stacked",
      },
    };
  } else if (type === "line") {
    return {
      id: ExploreSeriesId.check(uuid()),
      type,
      tooltipMode: "auto",
    };
  } else {
    assertNever(type, type);
  }
}

export const defaultExploreAggregation = (
  channel: ExploreChannel,
  type: DisplayTableColumnOutputType,
): ExploreField["aggregation"] => {
  switch (channel) {
    case "y-axis":
    case "opacity":
    case "tooltip":
      return type === "NUMBER" ? "Sum" : "Count";

    case "color":
      return type === "NUMBER" ? "Sum" : undefined;

    case "x-axis":
    case "h-facet":
    case "v-facet":
      return undefined;

    // TODO: not sure if these pivot ones are right or not
    case "row":
    case "column":
      return undefined;
    case "value":
      return type === "NUMBER" ? "Sum" : "Count";

    default:
      guardNever(channel, channel);
      return undefined;
  }
};

export function defaultExploreField({
  aggregation,
  channel,
  dataType,
  displayFormat,
  scaleType,
  seriesId,
  truncUnit,
  value,
}: {
  channel: ExploreChannel;
  value: string;
  seriesId: ExploreSeriesId;
  dataType: CalciteType;
  scaleType?: ChartDataType;
  displayFormat?: ExploreField["displayFormat"];
  aggregation?: HqlAggregationFunction;
  truncUnit?: HqlTruncUnit;
}): ExploreField {
  return {
    id: ExploreFieldId.check(uuid()),
    channel: channel,
    seriesId: seriesId,
    value: value,
    dataType,
    scaleType,
    truncUnit,
    displayFormat,
    aggregation,
  };
}
